import React from 'react';
import {ToggleButton} from "react-bootstrap";

function UiToggleButton({value, handleChange, text, isChecked}) {
    if (isChecked===undefined) {
        isChecked = false;
    }
    if (text===undefined) {
        text = value;
    }
    return (
        <ToggleButton
            id={"toggle-check-" + value}
            className="justify-content-between"
            key={"interval-" + value}
            type="checkbox"
            variant="outline-secondary"
            checked={isChecked}
            onChange={handleChange}
            value={value}
        >{text}
        </ToggleButton>
    );
}

export default UiToggleButton;