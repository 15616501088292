import React, {Fragment, useState, useEffect} from 'react';
import RestClient from "../../restapi/RestClient";
import AppUrl from "../../restapi/AppUrl";
import {useDispatch, useSelector} from "react-redux";
import {authActions, getError, getIsLoading, getSignedIn} from "../../store/authSlice";
import { useNavigate } from "react-router-dom";
import {Alert, Button, Col, Container, FloatingLabel, Form, Row} from 'react-bootstrap';
import UiSpinner from "../UI/UiSpinner";

function SignIn() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector(getIsLoading);
    const signedIn = useSelector(getSignedIn);
    const error = useSelector(getError);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    useEffect(() => {
        dispatch(authActions.setError(''));
        dispatch(authActions.setIsLoading(false));
    },[]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const loginData = {
            email:e.target.email.value,
            password:e.target.password.value,
        };
        dispatch(authActions.setIsLoading(true));
        dispatch(authActions.setError(''));
        RestClient.PostRequest(AppUrl.LogIn,loginData)
            .then((resData)=>{
                if (resData) {
                    dispatch(authActions.signIn(resData));
                    if (resData.user && resData.token && resData.expires) {
                        navigate('/poll');
                    }
                }
            }).catch((error)=>{
                dispatch(authActions.setError(error.message));
                dispatch(authActions.signOut());
            })
            .finally((error)=>{
                dispatch(authActions.setIsLoading(false));
        });
    }

    return (
        <Form onSubmit={handleSubmit} data-bs-theme="dark">
            <Container className="overflow-hidden text-center">
                <Row className="g-2 justify-content-center">
                    <Col xs={11} sm={9} md={7} lg={6} xl={5} xxl={4}>
                        <FloatingLabel
                            controlId="floatingEmail"
                            label="Email address"
                            className="mb-3"
                        >
                            <Form.Control
                                placeholder="name@example.com"
                                name="email"
                                required
                                onChange={(e)=>setEmail(e.target.value)}
                                value={email}
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="g-2 justify-content-center">
                    <Col xs={11} sm={9} md={7} lg={6} xl={5} xxl={4}>
                        <FloatingLabel
                            controlId="floatingPassword"
                            label="Password"
                            className="mb-3"
                            data-bs-theme="dark">
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                name="password"
                                required
                                onChange={(e)=>setPassword(e.target.value)}
                                value={password}
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="g-5 justify-content-center">
                    <Col xs={12} >&nbsp;
                        <Button type="submit" data-bs-theme="dark"
                                disabled={isLoading}
                                variant="outline-warning"
                        >
                            Sign in
                        </Button>
                    </Col>
                    <Col xs={11} sm={9} md={7} lg={6} xl={5} xxl={4} className="my-0 py-0">&nbsp;
                        {isLoading && <UiSpinner />}
                        {!isLoading && error.length>0 && <Alert variant="danger"
                                                       data-bs-theme="dark"
                                                       className="gx-5"
                        ><small>{error}</small>
                        </Alert>
                        }
                    </Col>
                </Row>
            </Container>
        </Form>
    );
}

export default SignIn;