import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import RestClient from "../../restapi/RestClient";
import AppUrl from "../../restapi/AppUrl";
import {Container, Row, Col, Badge, ListGroupItem, ListGroup, Card, Table, Alert, ButtonGroup, ToggleButton} from "react-bootstrap";
import UiToggleButton from "../UI/UiToggleButton";
import UiTableHeader from "../UI/UiTableHeader";
import UiSpinner from "../UI/UiSpinner";

import {
    Cart2,
    Person,
    CurrencyDollar,
    ClipboardData, ClipboardCheck
} from "react-bootstrap-icons";

function Stats(props) {
    const authState = useSelector(state=>state.auth);
    const [topProducts, setTopProducts] = useState([]);
    const [compViewTopProducts, setCompViewTopProducts] = useState(<Fragment />);
    const [compViewStats, setCompViewStats] = useState(<Fragment />);
    const [statsInterval, setStatsInterval] = useState("L3");
    const [isLoading, setIsLoading] = useState(true);
    const [alertMessage, setAlertMessage] = useState("Statistics");

    const fetchStats=()=>{
        setIsLoading(true);
        RestClient.PostRequest(AppUrl.GetStatistics,{interval:statsInterval},{bearer:authState.token})
            .then(resData=>{
                if (resData) {
                    setCompViewStats(renderViewStats(resData.participants));
                    setAlertMessage(resData.message);
                    setIsLoading(false);
                }
            })
            .catch(()=>{});
    }

    useEffect(() => { //component effect
        fetchStats();
    },[]);

    useEffect(() => { //itemsRender & selectedLogbookId effect
        fetchStats();
    },[statsInterval]);

    const renderViewStats=(participants)=>{
        const tableRows = participants.map((puser, idx) => {
            let className = "";
            return (<tr key={"th-"+puser.name}>
                    <td className={className}>{puser.name}</td>
                    <td className={className}>{puser.meetings}</td>
                    <td className={className}>{puser.total_spent}</td>
                    <td className={className}>{Math.round(puser.total_spent/puser.meetings)}</td>
                    <td className={className}><small>
                        {puser.first_quantity>0 && <Fragment>
                            {puser.first_product} <Badge bg="secondary">{puser.first_quantity}</Badge>
                        </Fragment>}
                        {puser.second_quantity>0 && <Fragment><br />
                            {puser.second_product} <Badge bg="secondary">{puser.second_quantity}</Badge>
                        </Fragment>}
                        {puser.third_quantity>0 && <Fragment><br />
                            {puser.third_product} <Badge bg="secondary">{puser.third_quantity}</Badge>
                        </Fragment>}
                        </small>
                    </td>
                </tr>
            )
        });
        return (
            <Table striped
                   bordered
                   hover
                   data-bs-theme="dark"
                   variant="dark"
                   className="table-sm"
            >
                <thead>
                <tr key={"th-"+1}>
                    <UiTableHeader
                        text={<Fragment>Nick</Fragment>}
                        icon={<Person className="fs-3" />}
                    />
                    <UiTableHeader
                        text={<Fragment>Lbks</Fragment>}
                        icon={<ClipboardCheck className="fs-3" />}
                    />
                    <UiTableHeader
                        text={<Fragment>Total</Fragment>}
                        icon={<CurrencyDollar className="fs-3" />}
                    />
                    <UiTableHeader
                        text={<Fragment>Avg</Fragment>}
                        icon={<ClipboardData className="fs-3" />}
                    />
                    <UiTableHeader
                        text={<Fragment>Top products</Fragment>}
                        icon={<Cart2 className="fs-3" />}
                    />
                </tr>
                </thead>
                <tbody>
                {tableRows}
                </tbody>
            </Table>
        );
    }

    const handleLogbookChange=(e)=>{
        setStatsInterval(e.target.value);
    }

    if (isLoading) {
        return (<UiSpinner message="Data is loading" />);
    }

    return (
        <Container className="container overflow-hidden">
            <Row className="justify-content-center gy-5">
                <Col lg={8} xl={7}>
                    <Alert variant="primary" className="border border-light-subtle" data-bs-theme="dark">
                        <small>{alertMessage}</small>
                    </Alert>
                <ButtonGroup className="mb-2">
                    <UiToggleButton handleChange={handleLogbookChange}
                        value={0} isChecked={statsInterval==="0"} text="All"/>
                    <UiToggleButton handleChange={handleLogbookChange}
                                    value={"L1"} isChecked={statsInterval==="L1"} text="L 1"/>
                    <UiToggleButton handleChange={handleLogbookChange}
                                    value={"L2"} isChecked={statsInterval==="L2"} text="L 2"/>
                    <UiToggleButton handleChange={handleLogbookChange}
                                    value={"L3"} isChecked={statsInterval==="L3"} text="L 3"/>
                    <UiToggleButton handleChange={handleLogbookChange}
                                    value={"1M"} isChecked={statsInterval==="1M"} text="1 M"/>
                    <UiToggleButton handleChange={handleLogbookChange}
                                    value={"3M"} isChecked={statsInterval==="3M"} text="3 M"/>
                    <UiToggleButton handleChange={handleLogbookChange}
                                    value={"6M"} isChecked={statsInterval==="6M"} text="6 M"/>
                    <UiToggleButton handleChange={handleLogbookChange}
                                    value={"1Y"} isChecked={statsInterval==="1Y"} text="1 Y"/>
                </ButtonGroup>
                </Col>
            </Row>
            <Row className="justify-content-center gy-3 pt-2">
                <Col lg={8} xl={7}>

                    {compViewStats}
                </Col>
            </Row>
        </Container>
    );
}

export default Stats;
