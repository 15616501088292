import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import RestClient from "../../restapi/RestClient";
import AppUrl from "../../restapi/AppUrl";
import {Container, Row, Col,ListGroup, Card, Table, Alert} from "react-bootstrap";
import UiBadgeIcon from "../UI/UiBadgeIcon";
import {Cart2, ClipboardCheck } from "react-bootstrap-icons";

function Profile(props) {
    const authState = useSelector(state=>state.auth);
    const [topProducts, setTopProducts] = useState([]);
    const [compViewTopProducts, setCompViewTopProducts] = useState(<Fragment />);

    const fetchTopProducts=()=>{
        RestClient.PostRequest(AppUrl.Top10Products,{},{bearer:authState.token})
            .then(resData=>{
                if (resData) {
                    setTopProducts(resData.products);
                }
            })
            .catch(()=>{});
    }

    useEffect(() => { //component effect
        fetchTopProducts();
    },[]);

    useEffect(() => { //itemsRender & selectedLogbookId effect
        setCompViewTopProducts(renderViewTopProducts());
    },[topProducts]);

    const renderViewTopProducts=()=>{
        let viewProducts = topProducts.map((item,index)=>{
            return (
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                        key={"il-"+index}
                        variant="dark"
                    >
                        <div className="ms-0 me-auto fs-6">
                            {item.name}
                        </div>
                        <UiBadgeIcon
                            icon={<ClipboardCheck className="fs-4" />}
                            text={item.tcount}
                            className="p-1 ps-4"
                        />
                        <UiBadgeIcon
                            icon={<Cart2 className="fs-4" />}
                            text={item.tquantity}
                            className="p-1 ps-4"
                        />
                    </ListGroup.Item>
            );
        });
        return (<ListGroup as="ol" numbered
                           data-bs-theme="dark"
                           key="lg-1"
                           variant="flush"
                           >
            {viewProducts}
        </ListGroup>);
    }

    return (
        <Container className="container overflow-hidden">
            <Row className="justify-content-center gy-5">
                <Col lg={8} xl={7}>
                    <Card border="dark" bg="dark" text="light">
                        <Card.Header>Top 10 items for {authState.user.name}</Card.Header>
                        {compViewTopProducts}
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Profile;
