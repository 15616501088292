import React, {Component, Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Footer from "../components/Footer/Footer";
import Stats from "../components/Stats/Stats";

class StatsPage extends Component {
    render() {
        return (
            <Fragment>
                <div className="backgroundSettings">
                    <TopNavigation />
                    <div className="servicesOverlay">
                        <Stats />
                    </div>
                    <Footer />
                </div>
            </Fragment>
        );
    }
}

export default StatsPage;