class AppUrl {
    //static BaseURL = 'http://192.168.1.99:22888/api';
    static BaseURL = 'https://birtlara.n0.ro/api';

    static LogIn = this.BaseURL + '/login';
    static VerifyToken = this.BaseURL + '/verifyexpiration';
    //poll
    static VotePoll = this.BaseURL + '/votepoll';
    static RecentPolls = this.BaseURL + '/recentpolls';
    static LatestActivePoll = this.BaseURL + '/latestactivepoll';
    static GetAnswers = this.BaseURL + '/getanswers';
    static UpdatePollMeeting = this.BaseURL + '/updatepollmeeting';
    //logbooks
    static GetLogbooks = this.BaseURL + '/getlogbooks';
    static UpdateLogbook = this.BaseURL + '/updatelogbook';
    static RemoveLogbook = this.BaseURL + '/removelogbook';
    static CopyLogbook = this.BaseURL + '/copylogbook';
    //item
    static GetItems = this.BaseURL + '/getitems';
    static UpdateItem = this.BaseURL + '/updateitem';
    static AddItem = this.BaseURL + '/additem';
    static RemoveItem = this.BaseURL + '/removeitem';
    static ClearItems = this.BaseURL + '/clearitems';

    static GetProducts = this.BaseURL + '/getproducts';
    static Top10Products = this.BaseURL + '/top10products';
    static GetParticipantsByLogbook = this.BaseURL + '/getparticipantsbylogbook';
    //static GetParticipantsByMeeting = this.BaseURL + '/getparticipantsbymeeting';
    static GetMeetings = this.BaseURL + '/getmeetings';
    static LatestMeeting = this.BaseURL + '/latestmeeting';
    static GetSharedCheck = this.BaseURL + '/getsharedcheck';
    static GetStatistics = this.BaseURL + '/statistics';
    //deprecated
    static ShowAnswers = this.BaseURL + '/showanswers/'; // ends with slash
    //static AddPoll = this.BaseURL + '/addpoll';
}

export default AppUrl;