import React, {Component, Fragment} from 'react';
import {Navbar, Nav, Container } from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";

function TopNavigation() {
    const authState = useSelector(state=>state.auth);
    let isSignedIn = authState.signedIn;
    const state={
        navBarBrand:"navBrand",
        navBarBack: "navBackground",
        navBarLink: "nav-link",
        navBarVariant: "dark",
    }
    return (
        <Fragment>
            <Navbar collapseOnSelect expand="sm"
                    fixed="top"
                    variant={state.navBarVariant}
                    data-bs-theme="dark"
                    className="navBackground">
                <Container>
                    <Navbar.Brand className={state.navBarBrand} as={NavLink} to="/">B.I.R.T.</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            {isSignedIn && <Nav.Link as={NavLink} to="/poll">Vote</Nav.Link>}
                            {isSignedIn && <Nav.Link as={NavLink} to="/logbook">Logbook</Nav.Link>}
                            {isSignedIn && <Nav.Link as={NavLink} to="/check">Check</Nav.Link>}
                        </Nav>
                        <Nav>
                            {isSignedIn && <Nav.Link as={NavLink} to="/stats">Stats</Nav.Link>}
                            {isSignedIn && <Nav.Link as={NavLink} to="/profile">Profile</Nav.Link>}
                            {isSignedIn && <Nav.Link as={NavLink} to="/signout">Sign Out</Nav.Link>}
                            {!isSignedIn && <Nav.Link as={NavLink} to="/signin">Sign In</Nav.Link>}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Fragment>
    );
}

export default TopNavigation;