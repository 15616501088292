import React, {Fragment} from 'react';
import { Routes, Route } from "react-router-dom";
import SignInPage from "../pages/SignInPage";
import SignOutPage from "../pages/SignOutPage";
import HomePage from "../pages/HomePage";
import LogbookPage from "../pages/LogbookPage";
import CheckPage from "../pages/CheckPage";
import ProfilePage from "../pages/ProfilePage";
import PollPage from "../pages/PollPage";
import StatsPage from "../pages/StatsPage";
import {useSelector} from "react-redux";

function AppRouter() {
    const isSignedIn = useSelector(state=>state.auth.signedIn);
    return (
        <Fragment>
            <Routes>
                <Route path="/" element={<HomePage />}/>
                {isSignedIn && <Route path="/poll" element={<PollPage />}/>}
                {isSignedIn && <Route path="/logbook" element={<LogbookPage />}/>}
                {isSignedIn && <Route path="/check" element={<CheckPage />}/>}
                {isSignedIn && <Route path="/profile" element={<ProfilePage />}/>}
                {isSignedIn && <Route path="/stats" element={<StatsPage />}/>}
                {isSignedIn && <Route path="/logbook/:logbookId" element={<LogbookPage />}/>}
                {isSignedIn && <Route path="/logbook/:logbookId/add" element={<LogbookPage />}/>}
                {isSignedIn && <Route path="/logbook/:logbookId/edit/:itemId" element={<LogbookPage />}/>}
                <Route path="/signin" element={<SignInPage />}/>
                <Route path="/signout" element={<SignOutPage />}/>
                {!isSignedIn && <Route path="*" element={<HomePage />}/>}
            </Routes>
        </Fragment>
    );
}

export default AppRouter;
